// import * as types from "../types";
// import { userService } from '../../services/userService';

const state = {

}
const getters = {

}
const mutations = {

}
const actions = {
// [types.LOGIN_USER]: ({ dispatch, commit }, payload) => {
//     return new Promise((res, rej) => {
//         userService.login()
//             .then(
//                 async data => {
//                     // const accessToken = data.data.validate_otp.data.accessToken
//                     // const token = data.data.validate_otp.data.accessToken
//                     // const refreshToken = data.data.validate_otp.data.refreshToken
//                     // userService.setAccessToken(accessToken)
//                     // userService.setRefereshToken(refreshToken)
//                     // commit(types.LOGIN_SUCCESS, {
//                     //     token: data.data.validate_otp.data.accessToken,
//                     //     accessToken: accessToken,
//                     //     refreshToken: refreshToken
//                     // })
//                     res(token)
//                 },
//                 error => {
//                     console.log(error)
//                     rej(error)
//                 }
//             )
//     })
// },
}
export const auth = {
  state, getters, mutations, actions
}
