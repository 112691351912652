<template>
  <ion-menu content-id="main-content" type="overlay">
    <ion-content>
      <ion-list id="inbox-list">
        <!-- <ion-list-header><ion-label>Direct VEDA Interface</ion-label></ion-list-header> -->
        <ion-list-header>
          <ion-badge v-if="isVedaOnline" color="success">VEDA ONLINE</ion-badge>
          <ion-badge v-else color="danger">VEDA OFFLINE</ion-badge>
        </ion-list-header>
        <ion-list-header>
          Direct VEDA Interface
        </ion-list-header>
        <ion-note>support@village.energy</ion-note>
        <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
          <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
            <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
  import {
    analyticsOutline,
    checkmarkDoneCircleOutline,
    cloudDownloadOutline,
    informationOutline,
    refreshOutline,
    wifiOutline
  } from 'ionicons/icons'
  import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonBadge
  } from '@ionic/vue'

  export default {
    name: 'Menu',
    props: { isVedaOnline: Boolean },
    data() {
      return {
        selectedIndex: 3,
        appPages: [
          {
            title: 'Dashboard',
            url: '/dashboard',
            iosIcon: analyticsOutline,
            mdIcon: analyticsOutline
          },
          {
            title: 'Production Test',
            url: '/production-test',
            iosIcon: checkmarkDoneCircleOutline,
            mdIcon: checkmarkDoneCircleOutline
          },
          {
            title: 'Diagnostics',
            url: '/diagnostics',
            iosIcon: informationOutline,
            mdIcon: informationOutline
          },
          {
            title: 'VEDA Details',
            url: '/veda-details',
            iosIcon: informationOutline,
            mdIcon: informationOutline
          },
          {
            title: 'Wi-Fi Credentials',
            url: '/update-wifi-settings',
            iosIcon: wifiOutline,
            mdIcon: wifiOutline
          },
          {
            title: 'Restart',
            url: '/restart-veda',
            iosIcon: refreshOutline,
            mdIcon: refreshOutline
          },
          {
            title: 'Software Update',
            url: '/software-update',
            iosIcon: cloudDownloadOutline,
            mdIcon: cloudDownloadOutline
          },
          {
            title: 'Factory Reset',
            url: '/factory-reset',
            iosIcon: refreshOutline,
            mdIcon: refreshOutline
          }
        ]
      }
    },
    components: {
      IonContent,
      IonIcon,
      IonItem,
      IonLabel,
      IonList,
      IonListHeader,
      IonMenu,
      IonMenuToggle,
      IonNote,
      IonBadge
    },
    created() {
      const path = window.location.pathname.split('folder/')[1]
      if (path !== undefined) {
        this.selectedIndex.value = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase())
      }
    }
  }
</script>

<style scoped src="./style.css">

</style>
