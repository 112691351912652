import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { IonicVue } from '@ionic/vue'
import axios from 'axios'
import mitt from 'mitt'
import VueAxios from 'vue-axios'
import { rootUri, redirectURI } from './store/api'
import { vedaService } from '@/services/vedaService'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
const emitter = mitt()

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
app.use(VueAxios, axios)
app.axios.defaults.baseURL = rootUri
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error && error.response && error.response.data && error.response.data.code === 'AccessTokenExpired') {
    window.location = redirectURI + vedaService.getVedaSN()
  }
  return Promise.reject(error)
})

app.config.globalProperties.emitter = emitter
router.isReady().then(() => {
  app.mount('#app')
})

export const dviApp = app
