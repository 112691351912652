import axios from 'axios'
import * as api from '../store/api'
import { utilService } from './util'
import { userService } from './userService'
import { VEDA_DETAILS_UPDATED } from '../events'

let eventEmitter
let isVedaOnline

const getIsVedaOnline = () => {
  return isVedaOnline
  // return false
}

const setIsVedaOnline = (value) => {
  isVedaOnline = value
}

const setEventEmitter = (em) => {
  eventEmitter = em
}

const getEventEmitter = () => {
  return eventEmitter
}

const getVedaDetails = () => {
  const detailsString = window.sessionStorage.getItem('veda-details')
  return JSON.parse(detailsString || '')
}

const saveVedaDeviceId = (deviceId) => {
  window.sessionStorage.setItem('veda-device-id', deviceId)
  getEventEmitter().emit(VEDA_DETAILS_UPDATED, true)
}

const saveVedaModelSchemaId = (modelSchemaId) => {
  window.sessionStorage.setItem('veda-model-schema-id', modelSchemaId)
}

const getVedaDeviceId = () => {
  return window.sessionStorage.getItem('veda-device-id')
}

const getVedaModelSchemaId = () => {
  return window.sessionStorage.getItem('veda-model-schema-id')
}

const getVedaSN = () => {
  return window.sessionStorage.getItem('veda-sn')
}

const getVedaProductId = () => {
  return window.sessionStorage.getItem('veda-product-id') || null
}

const getVedaMainPcbVer = () => {
  return window.sessionStorage.getItem('veda-main-pcb-ver') || null
}

const getDebug = () => {
  const debug = window.sessionStorage.getItem('debug') || 'false'
  return (debug === 'true')
}

const setVedaSNFromToken = (token) => {
  const vedaDetails = utilService.parseJwt(token)
  window.sessionStorage.setItem('veda-sn', vedaDetails.deviceSerialNumber)
  // getEventEmitter().emit(VEDA_DETAILS_UPDATED, true)
}

const setVedaProductId = (productId) => {
  window.sessionStorage.setItem('veda-product-id', productId)
}

const setVedaMainPcbVer = (mainPcbVer) => {
  window.sessionStorage.setItem('veda-main-pcb-ver', mainPcbVer)
}

const getMeterIds = () => {
  const vedaDetails = getVedaDetails()
  if (!vedaDetails || !vedaDetails.meters || !Array.isArray(vedaDetails.meters)) {
    throw Error('VEDA details not found')
  }
  const meterIds = vedaDetails.meters.map(meter => meter.meterId)
  return meterIds
}

const setDebugFromToken = (token) => {
  const vedaDetails = utilService.parseJwt(token)
  const debug = vedaDetails.debug || 'false'
  window.sessionStorage.setItem('debug', debug)
}

const saveVedaDetails = (details) => {
  window.sessionStorage.setItem('veda-details', JSON.stringify(details))
  if (details && details.device) {
    if (details.device.deviceId) saveVedaDeviceId(details.device.deviceId)
    if (details.device.modelSchemaId) saveVedaModelSchemaId(details.device.modelSchemaId)
    if (details.device.productId) setVedaProductId(details.device.productId)
    if (details.device.mainPcbVer) setVedaMainPcbVer(details.device.mainPcbVer)
  }
  // getEventEmitter().emit(VEDA_DETAILS_UPDATED, true)
}

const vedaDetails = () => {
  const sn = getVedaSN()
  return axios.get(api.deviceDetailsApi + `?deviceSerialNumber=${sn}&debug=true&limit=10`, {
    headers: {
      Authorization: `Bearer ${utilService.getJwt()}`
    }
  })
}

const factoryReset = () => {
  const deviceId = getVedaDeviceId()
  return axios.put(api.rootUri + api.factoryResetApi, {
    satAction: 'factoryResetDevice',
    deviceId,
    debug: getDebug()
  })
  // {"satAction":"factoryResetDevice","deviceId":"b8f009907af8","debug":true}
}

const updateVedaSoftware = () => {
  const deviceId = getVedaDeviceId()
  return axios.put(api.rootUri + api.updateVedaSoftware, {
    satAction: 'updateDeviceSoftware',
    deviceId,
    debug: getDebug()
  })
  // {"satAction":"factoryResetDevice","deviceId":"b8f009907af8","debug":true}
}

const rebootDevice = () => {
  const deviceId = getVedaDeviceId()
  return axios.put(api.rootUri + api.updateVedaSoftware, {
    satAction: 'rebootDevice',
    deviceId,
    debug: getDebug()
  })
  // {"satAction":"rebootDevice","deviceId":"b8f009907ae4","debug":true}
}

const scheduleDiagnostics = async (measure, scheduledStart, sampleInterval, sampleCount, scheduledEnd) => {
  const details = getVedaDetails()
  const params = {
    microgridId: details.device.microgridId,
    debug: getDebug(),
    diagParams: {
      target: [getVedaDeviceId()],
      measure,
      scheduledStart,
      sampleInterval,
      sampleCount,
      scheduledEnd
    }
  }
  console.log(params)
  const response = await axios.put('/dvi/schedulevedadiag', params)
  console.log(response)
  return response
}

const findDiagnosticsForDay = async (date) => {
  const details = getVedaDetails()
  return axios.put('/dvi/listvedadiagresults',
    {
      microgridId: details.device.microgridId,
      scheduledStart: date,
      debug: getDebug()
    })
}

const findDiagnosticsForDevice = async () => {
  const deviceId = getVedaDeviceId()
  const details = getVedaDetails()
  return axios.put('/dvi/listvedadiagresults', {
    microgridId: details.device.microgridId,
    deviceId,
    debug: getDebug()
  })
}

const downloadDiagnosticReport = async (report) => {
  // {"microgridId":"AU-6151-0001","scheduledStart":"20210412T060500Z","debug":true}
  const details = getVedaDetails()
  const payload = {
      microgridId: details.device.microgridId,
      scheduledStart: report.scheduledStart,
      debug: getDebug()
  }
  const queryString = utilService.parseUrlParams(payload)
  console.log('vedaService:downloadDiagnosticReport:queryString:\n', queryString)
  const response = await axios.get('/dvi/downloadvedadiagresults?' + queryString)
  console.log('vedaService:downloadDiagnosticReport:response:\n', response)
  return response
}

const circuitControlTest = async (satAction, circuitId, onOff, expiry) => {
  const details = getVedaDetails()
  const payload = {
    satAction: satAction,
    deviceId: details.device.deviceId,
    circuitId: circuitId,
    status: onOff,
    microgridId: details.device.microgridId,
    debug: getDebug()
  }
  if (typeof expiry !== 'undefined') payload['expiry'] = expiry
  console.log('vedaService:circuitControlTest:payload:', payload)
  const response = await axios.put(api.circuitControlTest, payload)
  console.log('vedaService:circuitControlTest:response:', response)
  return response
}

const getVedaEnergy = async (meterIds, localDates, returnData) => {
  const payload = {
    meterIds: meterIds,
    localDates: localDates,
    // debug: getDebug()
  }
  payload['returnData'] = (typeof returnData === 'undefined') ? 'aligned15sRead' : returnData
  // const querystring = new URLSearchParams(payload) // this doesn't work cuz' TS (2345)
  const queryString = utilService.parseUrlParams(payload)
  console.log('vedaService:getVedaEnergy:queryString:\n', queryString)
  const response = await axios.get('/dvi/vedaenergy?' + queryString)
  console.log('vedaService:getVedaEnergy:response:\n', response)
  return response
}

const prodTest = async () => {
  // prod test
}

// Returns the on/off status for all the circuits
const getCircuitStatus = async (deviceId) => {
  // userService.openToast('getting circuit status', 'warning')
  const debug = getDebug()
  const payload = {
    deviceId: deviceId,
    debug: debug
  }
  const queryString = utilService.parseUrlParams(payload)
  if (debug) console.log('vedaService:getCircuitStatus:queryString:\n', queryString)
  const response = await axios.get('/dvi/recentvedastatusmsg?' + queryString)
  if (response.data.length === 0) {
    // alert('Error on fetching circuit status. Please ping the device and try again')
    userService.openToast('Error on fetching circuit status. Please ping the device and try again', 'warning')
  }
  if (debug) console.log('getCircuitStatus:response\n', response)
  const statusMsgs = response.data.filter(sm => sm[0].circuitStatus || false) // filter msgs without circuitStatus
  if (debug) console.log('getCircuitStatus:statusMsgs\n', statusMsgs)
  // const mostRecentStatus = response.data[response.data.length - 1][0].circuitStatus
  const mostRecentStatus = statusMsgs[statusMsgs.length - 1][0].circuitStatus
  if (debug) console.log('getCircuitStatus:mostRecentStatus\n', mostRecentStatus)
  const formattedResponse: any = []
  if (mostRecentStatus) {
    const totalNumberOfCircuits = mostRecentStatus.length
    // console.log('getCircuitStatus:totalNumberOfCircuits: ', totalNumberOfCircuits)
    for (let i = 0; i < totalNumberOfCircuits; i++) {
      // console.log(i, mostRecentStatus[i])
      const ci = i + 1
      const status = mostRecentStatus[i]['circuit' + ci.toString()] === 'on' ?  true : false
      const data = {
        circuitId: ci,
        status,
        toPrice: mostRecentStatus[i].toPrice
      }
      formattedResponse.push(data)
    }
  }
  if (debug) console.log('vedaService:getCircuitStatus:formattedResponse:\n', formattedResponse)
  return formattedResponse
}

const pingDeviceWifi = async (deviceId, returnRecentStatusMsg) => {
  const payload = {
    satAction: 'pingDevice',
    deviceId: deviceId,
    returnRecentStatusMsg,
    debug: getDebug()
  }
  try {
    const response = await userService.createWifiAp(payload)
    if (response.data.data.length === 0) {
      setIsVedaOnline(false)
      return { isMqttPresent: false }
    }
    setIsVedaOnline(true)
    return { isMqttPresent: true }
  } catch (error) {
    console.log('vedaService:pingDeviceWifi:error\n', error)
    return { isError: true, error }
  }
}

const getMeterEnergyFlow = async () => {
  const meterIds = getMeterIds()
  const response = await axios.put('/energy', {
    satAction: 'getMeterEnergyFlow',
    meterIds,
  })
  return response
//   await fetch("https://cddevapi.village.energy/sat/v1/energy?null=", {
//     "credentials": "include",
//     "headers": {
//         "User-Agent": "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:89.0) Gecko/20100101 Firefox/89.0",
//         "Accept": "*/*",
//         "Accept-Language": "en-US,en;q=0.5",
//         "Content-Type": "application/json",
//         "Authorization": "Bearer eyJraWQiOiJsKzk2M2ZcL0FGaGZyMk5ScklcL1hZSldOdVNWUE9ublhYcVlsMGxvdTZBdGM9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwODllZTg5NS1hZmFkLTQ2NWQtODEzMS1hZTFkMzA3MWIwMTciLCJjb2duaXRvOmdyb3VwcyI6WyJzdXBwb3J0VGVhbSIsImFwLXNvdXRoLTFfQ29pVEFrQlB5X1ZpbGxhZ2VFbmVyZ3ktTzM2NSJdLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6Im9wZW5pZCBlbWFpbCIsImF1dGhfdGltZSI6MTYyMzM0MjYwOCwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoLTEuYW1hem9uYXdzLmNvbVwvYXAtc291dGgtMV9Db2lUQWtCUHkiLCJleHAiOjE2MjMzNDk4MTAsImlhdCI6MTYyMzM0MjYxMCwidmVyc2lvbiI6MiwianRpIjoiNzM2OWM2MzEtNGRkYS00Y2FiLTkwMjctMTQ4YjYzMGE1YTQ2IiwiY2xpZW50X2lkIjoiMXZ1ODZ2b3Fia2JsamFvNXVrZmdkcmlxMjgiLCJ1c2VybmFtZSI6InZpbGxhZ2VlbmVyZ3ktbzM2NV90ZXN0LnVzZXJAdmlsbGFnZS5lbmVyZ3kifQ.rNwimkr-kM6lFz6dyO7D1r21Lqa21NCf3Sm1AlEYq4kbiVT0dl7ydsveN1ujk1pa8mUDK2Xu4YlpY7NX90f_OewQhpPafxPEvtiaygBYp1qIAhwH5gOGvcEDgWPH9HU2544BEHyGJGs2XApxBUOYyvN8ywYR6naiiSgIPlD8pmj7C-IgVVcMaCTuaxe1rNi4XxVjAhWwy_OOA2uyqUGXj_Hix1pyMjvMH3YIbvakNNHJtFTts1yyW_9qAUuzvZ1i0qScKPWqtLHZI36w0ohbDK9rKWDGjJFJt0ulDBjFZNbBIKbs-92r41gE018d6ehIJW5ddFPcTAgJgNrDU6dz3Q"
//     },
//     "referrer": "http://localhost:8080/",
//     "body": "{\"satAction\":\"getMeterEnergyFlow\",\"meterIds\":[\"test-907af0\",\"test-9sm87\"],\"debug\":false}",
//     "method": "PUT",
//     "mode": "cors"
// });
}

const getMeterEnergy = async () => {
  const meterIds = getMeterIds()
  const response = await axios.put('/energy', {
    satAction: 'getMeterEnergy',
    returnData: 'aligned30minRead',
    splitExportImport: true,
    localDates: ['20210610'],
    meterIds
  })
  return response
}

const getMeterDetails = async (meter) => {
  const debug = getDebug()
  return await axios.get(`/meter?meterId=${meter}&includePreviouslyOwnedMeters=false&dataSource=gmc&debug=${debug}&limit=100`)
}

const getMeterSourceMsg = async (meters, start, delta) => {
  let meterIds = [] as any
  if (!meters) {
    meterIds = getMeterIds() // get all the meterids, by default
  } else if (Array.isArray(meters)) {
    meterIds = meters
  } else {
    meterIds = [meters]
  }

  const params = {
    satAction: 'getMeterSourceMsg',
    start: start || (new Date().toISOString()).replaceAll('-','').replaceAll(':', ''),
    deltaInSeconds: delta || 120,
    meterIds,
    debug: getDebug()
  }
  return axios.put('/energy', params)
}

const remoteTestDevice = async() => {
  const details = getVedaDetails()
  return axios.put('/iot', {
		satAction: 'remoteTestDevice',
		deviceId: details.device.deviceId,
		microgridId: details.device.microgridId,
		debug: getDebug()
	})
}

async function getProdTests() {
  const deviceId = getVedaDeviceId()
  const debug = getDebug()
  return axios.get(`/dvi/vedaprodtestresults?deviceId=${deviceId}&debug=${debug}`)
}

const saveCircuitExpiry = (circuitId, expiry) => {
  const circuitStatusExpiry = JSON.parse(sessionStorage.getItem('circuit-expiry') || '{}')
  const deviceId = getVedaDeviceId() || 'deviceId' // this || is only for the lint
  circuitStatusExpiry[deviceId] = circuitStatusExpiry[deviceId] || {}
  circuitStatusExpiry[deviceId][circuitId] = expiry
  sessionStorage.setItem('circuit-expiry', JSON.stringify(circuitStatusExpiry))
}

const getCircuitExpiry = (circuitId) => {
  const circuitStatusExpiry = JSON.parse(sessionStorage.getItem('circuit-expiry') || '{}')
  const deviceId = getVedaDeviceId() || 'deviceId' // this || is only for the lint
  circuitStatusExpiry[deviceId] = circuitStatusExpiry[deviceId] || {}
  return circuitStatusExpiry[deviceId][circuitId]
}

export const vedaService = {
  vedaDetails,
  getVedaDetails,
  saveVedaDetails,
  saveVedaDeviceId,
  saveVedaModelSchemaId,
  getVedaDeviceId,
  getVedaModelSchemaId,
  getVedaSN,
  getDebug,
  setVedaSNFromToken,
  setDebugFromToken,
  setEventEmitter,
  getEventEmitter,
  factoryReset,
  updateVedaSoftware,
  rebootDevice,
  scheduleDiagnostics,
  findDiagnosticsForDay,
  downloadDiagnosticReport,
  circuitControlTest,
  getVedaEnergy,
  getCircuitStatus,
  pingDeviceWifi,
  getMeterEnergyFlow,
  getMeterEnergy,
  getMeterDetails,
  getMeterSourceMsg,
  remoteTestDevice,
  getIsVedaOnline,
  setIsVedaOnline,
  getProdTests,
  findDiagnosticsForDevice,
  saveCircuitExpiry,
  getCircuitExpiry,
  getMeterIds
}
