const JWT = 'jwt'

function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

// to parse JSON object payload for GET requests
function parseUrlParams (obj) {
  let str = ''
  for (const key in obj) {
    if (str !== '') {
      str += '&'
    }
    str += key + '=' + encodeURIComponent(obj[key])
  }
  return str
}

function setJwt (token) {
  window.sessionStorage.setItem(JWT, token)
}

function getJwt () {
  return window.sessionStorage.getItem(JWT)
}

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const roundTo = (n, digits) => {
  if (!isNumeric(n)) return n
  n = parseFloat(n)
  let negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  const multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(digits)
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return parseFloat(n)
}

export const roundToTwo = (num) => {
  return roundTo(num, 2)
}

export const hasProp = (thisObj, key) => {
  if (!thisObj) return false
  return Object.prototype.hasOwnProperty.call(thisObj, key)
}

export const padTo = (n, digits) => {
  // pad number with 0's in 'missing' decimal places, e.g. n .. 0.43 with digits .. 5 should result in 0.43000
  // n is float or string and returns string
  n = '' + n
  const maxZeros = '0'.repeat(digits)
  if (n.includes('.')) {
    const nArray = n.split('.')
    return nArray[0] + '.' + (nArray[1] + maxZeros).substr(0, digits)
  } else {
    return n + '.' + maxZeros
  }
}

export const padToTwo = (n) => {
  return padTo(n, 2)
}

export const sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const addToLog = (logMsg, debug) => {
  const shortDebug = (String(debug)).substr(0, 20)
  if (typeof debug === 'undefined') debug = false
  if (debug) console.log(logMsg)
}

export const utilService = {
  setJwt,
  getJwt,
  parseJwt,
  parseUrlParams
}
