import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/welcome'
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/Folder.vue')
  },
  {
    path: '/welcome',
    component: () => import ('../views/Welcome.vue')
  },
  {
    path: '/dashboard',
    component: () => import ('../views/guarded/Dashboard.vue')
  },
  {
    path: '/create-wifi-ap',
    component: () => import ('../views/CreateWifiAP.vue')
  },
  {
    path: '/create-wifi-ap-success',
    component: () => import ('../views/CreateWifiAPSuccess.vue')
  },
  {
    path: '/connect-to-wifi',
    component: () => import ('../views/ConnectToWifi.vue')
  },
  {
    path: '/offline-mode',
    component: () => import ('../views/OfflineMode.vue')
  },
  {
    path: '/production-test',
    component: () => import ('../views/guarded/ProductionTest.vue')
  },
  {
    path: '/diagnostics',
    component: () => import ('../views/guarded/Diagnostics.vue')
  },
  {
    path: '/veda-details',
    component: () => import ('../views/Welcome.vue')
  },
  {
    path: '/update-wifi-settings',
    component: () => import ('../views/UpdateWiFiSettings.vue')
  },
  {
    path: '/restart-veda',
    component: () => import ('../views/RestartVeda.vue')
  },
  {
    path: '/software-update',
    component: () => import('../views/SoftwareUpdate.vue')
  },
  {
    path: '/factory-reset',
    component: () => import('../views/FactoryReset.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
