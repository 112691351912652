<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <appMenu :isVedaOnline="isVedaOnline"></appMenu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
  <ion-fab v-if="!isVedaOnline && currentPassword == defaultPassword && currentSSID == defaultSSID" vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button @click="initSetup()">
      SETUP
    </ion-fab-button>
  </ion-fab>
</template>

<script lang="ts">
  import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue'
  // import { ref } from 'vue';
  import { useRoute } from 'vue-router'
  import { defineComponent } from 'vue'
  import Menu from '@/components/Menu/Menu.vue'
  import { utilService } from '@/services/util'
  import { userService } from '@/services/userService'
  import { vedaService } from './services/vedaService'
  import mitt from 'mitt'
  import { LOGGED_IN } from '@/events'
  import router from './router'
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const emitter = mitt()
  userService.setEventEmitter(emitter)
  const vedaEmitter = mitt()
  vedaService.setEventEmitter(vedaEmitter)

  export default defineComponent({
    name: 'App',
    data: () => {
      return {
        isVedaOnline: false,
        currentSSID: '.', // to keep the original and current values different
        defaultSSID: '',
        currentPassword: '.',
        defaultPassword: ''
      }
    },
    components: {
      IonApp,
      IonRouterOutlet,
      IonSplitPane,
      appMenu: Menu
    },
    methods: {
      async wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        })
      },
      initSetup() {
        window.location.pathname = '/create-wifi-ap'
        console.log('App.vue:initSetup')
      },
      async pingDeviceWifi() {
        const deviceId = vedaService.getVedaDeviceId()
        // sometimes the VEDA doesn't return mqtt urls in data response field
        // a way around this is to send two pings... the second one after some delay
        // the result of second ping can be used to see if VEDA is online/offline
        let res = await vedaService.pingDeviceWifi(deviceId, false)
        console.log('pingDeviceWifi:res:first res', res)
        await this.wait(2000)
        res = await vedaService.pingDeviceWifi(deviceId, true)
        console.log('pingDeviceWifi:res:second res', res)
        if (res.isError) {
          // error while fetching response
          userService.openToast('Unexpected error while pinging VEDA', 'danger')
        } else if (!res.isMqttPresent) {
          // data is empty
          userService.openToast('VEDA seems to be offline!', 'danger')
          this.isVedaOnline = false
        } else if (res.isMqttPresent) {
          // alert('Wi-Fi test success')
          this.isVedaOnline = true
        }
      }
    },
    async created() {
      const route = useRoute()
      if (route.query.jwt) {
        try {
          userService.setAccessToken(null)
          userService.setRefereshToken(null)
          utilService.setJwt(route.query.jwt)
          vedaService.setVedaSNFromToken(route.query.jwt)
          vedaService.setDebugFromToken(route.query.jwt)
          const vedaDetails = await vedaService.vedaDetails()
          console.log(vedaDetails.data.data.device)
          vedaService.saveVedaDetails(vedaDetails.data.data.device)
          vedaService.saveVedaDeviceId(vedaDetails.data.data.device.deviceId)
          const data = await userService.login()
          userService.setAccessToken(data.data.dviAccessToken)
          userService.setRefereshToken(data.data.dviRefreshToken)
          console.log(vedaService.getVedaDetails())
          userService.setAuthToken(userService.getAccessToken())
          if (location.href.includes('?')) {
            // history.pushState({}, '', location.href.split('?')[0])
            router.push('/')
          }
          console.log('found jwt')
          userService.getEventEmitter().emit(LOGGED_IN, true)
          setInterval(async () => {
            const response = await userService.refreshAccessToken()
            if (response && response.data) {
              userService.setAccessToken(response.data.dviAccessToken)
              userService.setRefereshToken(response.data.dviRefreshToken)
              userService.setAuthToken(userService.getAccessToken())
              console.log('updated access and refresh token')
            }
          }, 30000)
        } catch(err) {
          console.error(err)
        }
      } else {
        if(!vedaService.getVedaDeviceId()) {
          // alert('Please access the URL through the QR Code')
          userService.openToast('Please access the URL through the QR Code', 'warning')
        } else {
          console.log('else')
          userService.setAuthToken(userService.getAccessToken())
          setTimeout(() => {
            userService.getEventEmitter().emit(LOGGED_IN, true)
          })
        }
      }
    },
    async mounted() {
      setTimeout(this.pingDeviceWifi, 2000)
      setInterval(this.pingDeviceWifi, 15000)
      const details = vedaService.getVedaDetails()
      setTimeout(() => {
        this.currentSSID = details.device.wifiSsid
        this.currentPassword = details.device.wifiPassword
        this.defaultSSID = details.device.defaultWifiSsid
        this.defaultPassword = details.device.defaultWifiPassword
      }, 500)
      // Just for testing
      // this.defaultSSID = details.device.wifiSsid
      // this.defaultPassword = details.device.wifiPassword
    },
  })
</script>

<style scoped>
</style>
