import { utilService } from './util'
import { vedaService } from './vedaService'
import { alertController, toastController } from '@ionic/vue'
import axios from 'axios'
import * as api from '../store/api'

const REFRESH_TOKEN = 'refreshToken'
const ACCESS_TOKEN = 'accessToken'

let eventEm

function login () {
  return axios.put(api.rootUri + api.login, {
    deviceSerialNumber: vedaService.getVedaSN(),
    deviceId: vedaService.getVedaDeviceId(), // deviceId to be taken from jwt
    debug: vedaService.getDebug(),
    targetVeEnvLoc: 've-cd-dev-in-1', // todo: review
    dviUseCase: 'uc3'
  }, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${utilService.getJwt()}`
    }
  })
}

let call
function makeRequestCreator (headers, query) {
  return url => {
    if (call) {
      call.cancel('Only one request allowed at a time.')
    }
    call = axios.CancelToken.source()
    return axios.post(url, { ...query }, {
      cancelToken: call.token,
      headers: headers
    })
  }
}
function getAccessToken () {
  return window.sessionStorage.getItem(ACCESS_TOKEN)
}

function setAccessToken (accessToken) {
  return window.sessionStorage.setItem(ACCESS_TOKEN, accessToken)
}
function getRefreshToken () {
  return window.sessionStorage.getItem(REFRESH_TOKEN)
}
function setRefereshToken (refreshToken) {
  return window.sessionStorage.setItem(REFRESH_TOKEN, refreshToken)
}
function setAuthToken (token) {
  axios.defaults.headers.common.Authorization = ''
  delete axios.defaults.headers.common.Authorization
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}
function deviceDetails () {
  return axios.get(api.deviceDetailsApi)
}
function createWifiAp ({ satAction, deviceId, returnRecentStatusMsg, debug }) {
  return axios.put(api.createWifiApApi, {
    satAction, deviceId, returnRecentStatusMsg, debug
  })
}
function updateWifiAp ({ satAction, deviceId, param1, param2, debug }) {
  return axios.put(api.createWifiApApi, {
    satAction, deviceId, param1, param2, debug
  })
}
function getSatUserId () {
  const token = getRefreshToken()
  if (token) {
    const tokenData = utilService.parseJwt(token)
    if (tokenData) {
      return tokenData.satUserId
    }
  }
}
function refreshAccessToken (payload) {
  console.log(payload)
  const refreshToken = getRefreshToken()
  const tokenData = utilService.parseJwt(refreshToken)
  let userId
  if (tokenData) {
    userId = tokenData.satUserId
  }
  if (!userId) {
    console.error('No user id, cannot renew access token')
    return
  }
  return axios.put(api.rootUri + api.refreshToken, {
    satUserId: userId,
    dviRefreshToken: refreshToken
  })
}
function setEventEmitter (event) {
  eventEm = event
}
function getEventEmitter () {
  return eventEm
}
async function createWifiPopup () {
  await alertController
    .create({
      cssClass: 'my-custom-class',
      header: 'VEDA Offline!',
      message: 'VEDA is not connected to the internet. Would you like to set-up a connection?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: blah => {
            console.log('userService:createWifiPopup:Popup:Confirm Cancel:', blah)
          }
        },
        {
          text: 'Okay',
          handler: () => {
            // this.$router.push('/create-wifi-ap')
            window.location.pathname = '/create-wifi-ap'
            console.log('userService:createWifiPopup:Popup:Confirm Okay')
          }
        }
      ]
    })
  // return alert.present()
}
// color -> primary, secondary, tertiary, success, warning, danger, light, medium, dark
async function openToast (message, color) {
  const toast = await toastController
    .create({
      duration: 5000,
      position: 'top',
      animated: true,
      mode: 'ios',
      color,
      message
    })
  return toast.present()
}
// was intended to be used across different components
// not in use atm as we're testing if VEDA is online/offline every 15 seconds
async function pingVedaOnMounted () {
  console.log('userService:pingVedaOnMounted:pinging VEDA...')
  const deviceId = vedaService.getVedaDeviceId()
  const res = await vedaService.pingDeviceWifi(deviceId, true)
  if (res.isError) {
    // error while fetching response
    openToast('Unexpected error while pinging VEDA', 'danger')
  } else if (!res.isMqttPresent) {
    // data is empty
    openToast('VEDA seems to be offline!', 'danger')
  } else {
    // alert('Wi-Fi test success')
  }
}

export const userService = {
  login,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefereshToken,
  makeRequestCreator,
  refreshAccessToken,
  setAuthToken,
  deviceDetails,
  getSatUserId,
  setEventEmitter,
  getEventEmitter,
  createWifiAp,
  updateWifiAp,
  createWifiPopup,
  openToast,
  pingVedaOnMounted
}
